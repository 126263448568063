<template>
  <router-link class="app-logo" to="/">
    <img
      v-if="!isHiddenImage"
      class="app-logo__image"
      :src="appLogoSrc"
      width="70"
      alt="Crypto Insights logo"
    />
    <div class="app-logo__text">
      <span class="app-logo__text--accent">Crypto</span>
      <span>Insights</span>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppLogo',
  props: {
    isHiddenImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      themeLight: 'user/themeLight',
    }),
    appLogoSrc() {
      return require(`@/assets/images/app-logo/app-logo-${
        this.themeLight ? 'light' : 'dark'
      }${window && window.devicePixelRatio > 1 ? '@2x' : ''}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-logo {
  display: flex;
  align-items: center;
  user-select: none;

  &__image {
    display: none;

    @include respond-to('sm') {
      width: 50px;
      margin-right: 18px;
      display: block;
    }

    @include respond-to('lg') {
      width: 70px;
      margin-right: 26px;
    }
  }

  &__text {
    margin-top: 4px;
    display: flex;
    font-family: var(--secondary-font-family);
    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-to('xl') {
      flex-direction: column;
      font-size: 30px;
    }

    &--accent {
      color: var(--safety-green-400);
    }
  }
}
</style>
